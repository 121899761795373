<template>
	<div class="box">
    <!-- 积分余额显示-->
    <div class="box_one">
      <div class="left">
        <div class="total-revenue">
          <div class="title">{{ $t('myintegral') }}</div>
          <div class="num">{{ myInfo.integral }}</div>
        </div>
      </div>
      <div class="right">
        <div class="top">
          <div @click="integralDetail" class="detail">{{ $t('integralDetail') }}</div>
          <Icon name="icon-7key-musicround-keyboard_arrow-2" color="#fff" width="10" height="10">
          </Icon>
        </div>
      </div>
    </div>
    <!--积分卡密兑换入口-->
    <div class="box_ot">
      <div class="ot_input">
        <el-input v-model="card_number" :placeholder="$t('kamiinputplace')"></el-input>
        <el-button @click="cardNumberExchangeIntegralF" type="success" style="margin-left: 10px;">{{ $t('amichongzhi')}}</el-button>
      </div>
    </div>

    <!--积分充值操作-->
<!--    <div class="box_two">-->
<!--      <div class="two_zong" v-for="(item,index) in integralPackageData" :index="item.id" :key="index">-->
<!--        <div class="two_aa">{{ item.name}}</div>-->
<!--        <div class="two_bb">{{ item.price}}</div>-->
<!--        <div class="two_cc">-->
<!--          <el-button @click="recharge(item)" type="warning" style="width: 100%;font-weight: bold;font-size: 16px"> 订 阅 </el-button>-->

<!--          <el-button v-if="lang != 'cn'" @click="rechargepaypal(item)" type="warning" style="width: 100%;font-weight: bold;font-size: 16px"> payPal </el-button>-->

<!--        </div>-->
<!--        <div v-for="value in item.attributeList" class="two_dd" :key="value">-->
<!--          <div class="two_dd_n">-->
<!--            <i class="el-icon-s-promotion" style="color: #E6A23C;margin-right: 5px;"></i>-->
<!--          </div>-->
<!--          <div class="two_dd_m">{{ value}}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <!-- 微信支付宝充值弹窗  -->
    <PopupCommon ref="popupRecharge" customClassName="textarea-modal" title=" " :visible.sync="visible">
      <div :slot="'title'" class="pop-title">{{ popTitle }}</div>
      <div :slot="'content'" class="pop-content">

        <pay-immediately-bottom :orderInfo="payInfo" @wechatPay="wechatPay" @alipayPay="alipayPay"></pay-immediately-bottom>
      </div>
      <div :slot="'footer'" class="pop-footer">
        <div class="confirm" @click="demoFinishPay">{{$t('recommend.completePayment')}}</div>
      </div>
    </PopupCommon>

    <!-- paypal充值确认弹窗 -->
    <PopupCommon ref="popupPPRecharge" customClassName="textarea-modal" title=" " :visible.sync="visible">
      <div :slot="'title'" class="pop-title">{{ popTitle }}</div>
      <div :slot="'content'" class="pop-ccontent">
        <div class="pop-con-one">
          {{ popupPpPRecharge.name }}
        </div>
        <div class="pop-con-one">
          {{ $t('paypaljine') }}：{{ popupPpPRecharge.price }}
        </div>
        <div class="pop-con-one">
          {{ $t('paypalzengsongjifen') }}：
          <i style="display: flex;align-items: center;margin-right: 5px" class="el-icon-coin"></i>
          {{ popupPpPRecharge.integral }}
        </div>
      </div>
      <div :slot="'footer'" class="pop-footer">
        <div class="confirm" @click="demoFinishPay">{{$t('recommend.completePayment')}}</div>
      </div>
    </PopupCommon>



    <!-- 积分明细弹窗 -->
    <PopupCommon ref="popupintegralDetail" customClassName="textarea-modal" title=" " :visible.sync="visible2" :width="'66vw'">
      <div :slot="'title'" class="pop-title">{{$t('integralDetail')}}</div>
      <div :slot="'content'" class="pop-content-submit1">
        <el-table :data="integralLogData" height="250" :header-style="{ border: '1px solid red' }" style="width: 100%">
          <el-table-column align="center" prop="way" :label="$t('integraltypetxt')"></el-table-column>
          <el-table-column align="center" prop="balance" :label="$t('integralshengyutxt')"></el-table-column>
          <el-table-column align="center" prop="time" :label="$t('integralshijiantxt')"></el-table-column>
        </el-table>
      </div>
    </PopupCommon>
	</div>
</template>

<script>
	import PopupCommon from "@/components/popup/PopupCommon.vue"
  import PayImmediatelyBottom from "@/components/content/PayImmediatelyBottom.vue"
  import {
    incomeLog,
    paypalpay,
    withdrawCash,
    recharge, getCheckOrder, moneyExchangeIntegral, integralLogGet, cardNumberExchangeIntegral, myInfo, integralDataBase
  } from "@/api/Mine"
  import {buyLockPrice} from "@/api/Home";
	export default {
		props: {},
		computed: {},
		components: {
      PayImmediatelyBottom,
			PopupCommon
		},
		data() {
			return {
        card_number:'',
        popupPpPRecharge:{
          name:'',
          price : '',
          integral: '',
        },
        lang:'cn',
        pay_integral_base_data_id: 0,
        exintegral: '',
        payType: '',//支付方式 1支付宝 2微信
        day: '',//锁定时间
        payInfo: {
          codeImg: '',
          time: 10 * 60,
          money: '',
          isWechat: '1', //是否支持微信支付 0不支持 1支持
          isApliy: '1', //是否支持支付宝支付 0不支持 1支持
          isCorporateTransfer: '1', //是否支持对公转账 0不支持 1支持
          corporateName: this.$t("namecomponent") + this.$t("tranfercomponentinfo"),
          bankDeposit: this.$t("bankaccountinfo"),
          bankAccount: '21050110084600000051'
        },
				visible1: false,
				visible2: false,
				visible: false,
        exForm: {exmoney: 0},
				popTitle: '', //弹窗标题
				type: 1, // 1充值 2提现
				myInfo: {},
				tableData: [],
        integralLogData: [],
        integralPackageData: [],
			}
		},
		created() {
			this.myInfo = JSON.parse(localStorage.getItem('myInfo'))
			this.integralDataBasefunc(); //积分基础套餐
		},
		methods: {
      // 更新用户信息
      MyInfo(){
        myInfo({}).then((res) => {
          localStorage.setItem('myInfo', JSON.stringify(res.data))
        }).catch((err) => {});
      },

      // 打开PAYPAL
      rechargepaypal(item) {
        const loading = this.$loading({
          lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'
        });

        let params = {};
        params.money = item.amount;
        params.typeOf = 6;
        params.pay_integral_base_data_id = item.id;
        console.log(params)
        paypalpay(params).then((res) => {
          if (res.errcode == 200) {
            loading.close();
            this.popTitle = this.$t('recharge')
            this.popupPpPRecharge.name = item.name
            this.popupPpPRecharge.price = item.price
            this.popupPpPRecharge.integral = item.integral
            this.$refs.popupPPRecharge.open()
            this.order_id = res.data.orderId;
            // 打开新窗口
            const url = res.data.approvalUrl;
            window.open(url, '_blank');
          } else {
            loading.close();
            let mmssgg = '网络开小差了 ~ '
            if(res.errmsg.length > 0){
              mmssgg = res.errmsg
            }
            this.$message({type: "error", message: mmssgg});
          }
        });

      },
      // ============================================ 卡密 =============================================
      // 卡密兑换积分
      cardNumberExchangeIntegralF(){
        cardNumberExchangeIntegral({'card_number':this.card_number}).then((res) => {
          if (res.status === 1) {
            // 更新用户信息
            this.MyInfo();
            const loading = this.$loading({
              lock: true,
              text: '正在努力兑换中...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
              loading.close();
              this.$router.go(0) && location.reload();
            }, 1000);
          }
        }).catch((err) => {});
      },

      // ============================================ 充值 =============================================
      // 打开充值弹窗
      recharge(type) {
        console.log(type)
        this.payInfo.money = type.amount
        this.pay_integral_base_data_id = type.id
        this.$refs.popupRecharge.open()
        this.popTitle = this.$t('recharge')
        this.moneyLabel = this.$t('rechargemoney')
        this.moneyPlaceholder = this.$t('inputrechargemoney')
      },
      // 微信支付
      wechatPay(data){
        console.log(data,'wechatPay',data)
        this.payType = data
        if(this.payType != ''){
          this.getPayCode(this.payInfo.money,this.payType)
        }
      },
      // 支付宝支付
      alipayPay(data){
        console.log(data,'alipayPay')
        this.payType = data
        if(this.payType != ''){
          this.getPayCode(this.payInfo.money,this.payType)
        }
      },
      //获取支付二维码
      getPayCode(money, payType) {
        let params = {};
        params.money = money;
        params.payType = payType;
        params.typeOf = 6;
        params.pay_integral_base_data_id = this.pay_integral_base_data_id;
        recharge(params).then((res) => {
          if (res.status == 1) {
            this.payInfo.codeImg = res.data.qr_code
            this.order_id = res.data.order_id
          } else {
            this.$message({type: "error", message: res.msg});
          }
        });
      },
      // 完成支付
      demoFinishPay(){
        let data = {};
        data.order_id = this.order_id
        getCheckOrder(data).then((res) => {
          if (res.status === 1) {
            // 更新用户信息
            this.MyInfo();
            this.$refs.popupRecharge.close()
            const loading = this.$loading({
              lock: true,
              text: '正在努力查询中...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
              loading.close();
              this.$router.go(0) && location.reload();
            }, 1000);
            // this.ruleForm.bankCard = ''
            // this.ruleForm.money = ''
            // this.payInfo.codeImg = ''
          }
        }).catch((err) => {
          // console.log(err, '获取banner失败');
        });
      },


			// 积分基础套餐
      integralDataBasefunc() {
        let lang = localStorage.getItem('lang')
        this.lang = lang
        integralDataBase({'lang':lang}).then((res) => {
						this.integralPackageData = res.data

					})
					.catch((err) => {
						console.log(err, '我的信息失败');
					});
			},

      // =============================== 明细 =======================================
      // 积分明细
      integralDetail() {
        integralLogGet({}).then((res) => {
          if (res.status === 1) {
            this.integralLogData = res.data
          }
          this.$refs.popupintegralDetail.open()
        }).catch((err) => {
          console.log(err);
        });
      },
      // ==================================== 积分 ==========================================
      //打开兑换积分弹窗
      exchangePop(){
        this.$refs.popupExchange.open()
      },
      // 兑换金额实时变化
      inputduihuan(e){
        this.exintegral = e*10
      },
      // 兑换积分接口
      finishduihuanjifen(){
        let data = {};
        data.money = this.exForm.exmoney
        data.integral = this.exintegral
        if(data.money > this.myInfo.wallet){
          this.$message({type: "warning",message: '账户余额不足，请充值'});
          return
        }
        moneyExchangeIntegral(data).then((res) => {
          if (res.status === 1) {
            // 更新用户信息
            this.MyInfo();
            this.$refs.popupExchange.close()
            const loading = this.$loading({
              lock: true,
              text: '正在努力兑换中...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
              loading.close();
              this.$router.go(0) && location.reload();
            }, 1000);
          }
        }).catch((err) => {});
      },
		}
	}
</script>

<style lang="less" scoped>
	* {
		::-webkit-scrollbar {
			/*隐藏滚轮*/
			display: none;
		}
	}

	::v-deep .el-input__inner {
		width: 402px;
		background: @recommendMusicianListBGColor;
		//border: none;
    border: 1px solid @popupLockTimeBorderColor;
		color: @uploadFormTextColor;

		&:hover {
			border: 1px solid @popupCancelTextColor;
		}
	}

	::v-deep .el-form-item__label {
		color: @recommendMusicianListTextColor;
	}

	.box {
    display: flex;
    flex-direction: column;

    .box_one {
      display: flex;
      justify-content: space-between;
      flex: 1 auto;
      color: #fff;
      // margin-left: 28px;
      width: 100%;
      height: 201px;
      // background: linear-gradient(134deg, #00BEA5 0%, #06D5BA 100%);
      background-image: url(../../../assets/images/personalCenter/account-balance.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border-radius: 8px;
      // align-items: center;
      .left {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 80%;
        .total-revenue {
          display: flex;
          flex-direction: row;
          align-items: center;
          .title{
            text-align: center;
          }
          .num {
            font-size: 48px;
            font-family: Roadgeek2005Engschrift;
            margin-left: 10px;
            color: #FFFFFF;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-right: 24px;
        width: 20%;
        .top {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .detail {
            margin-right: 8px;
            cursor: pointer;
          }
        }
      }
    }
    .box_ot {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
      width: 100%;
      border-radius: 8px;
      .ot_input {
        display: flex;
      }

    }

    .box_two {
      display: flex;
      flex-direction: row;
      //align-items: center;
      //justify-content: center;
      flex-wrap: wrap;

      .two_zong {
        border-radius: 8px;

        display: flex;
        flex-direction: column;
        background: @recommendMusicianListBGColor;
        padding: 10px 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        margin-top: 20px;
        width: 200px;
        height: 300px;
        .two_aa {
          display: flex;
          padding: 10px 10px;
          font-weight: bold;
        }
        .two_bb {
          display: flex;
          padding: 10px 10px;
          font-weight: bold;
        }
        .two_cc {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          margin-bottom: 10px;
          font-weight: bold;
        }
        .two_dd {
          display: flex;
          padding-top: 10px;
          font-size: 12px;
          line-height: 20px;
          .two_dd_n {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .two_dd_m {
            display: flex;
          }
        }
      }
    }



		// 充值弹窗
		.pop-title {
			height: 68px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid @demoitemtabsBGColor;
			padding: 0 25px;
			color: @recommendMusicianListTextColor;
		}

		.pop-content {
			// max-height: 86px;
			padding: 24px;
			border-bottom: 1px solid @demoitemtabsBGColor;
			padding: 25px;
			color: @recommendMusicianListTextColor;
			line-height: 22px;

			.money-desc {
				font-size: 12px;
				font-weight: 400;
				color: #00BEA5;
			}
		}

    .pop-ccontent {
      padding: 24px;
      border-bottom: 1px solid @demoitemtabsBGColor;
      padding: 25px;
      color: @recommendMusicianListTextColor;
      line-height: 22px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .pop-con-one{
        padding: 10px;
        display: flex;
      }
    }

		.pop-footer {
			display: flex;
			align-items: center;
			height: 68px;
			justify-content: center;

			.cancel {
				width: 102px;
				height: 36px;
				border-radius: 4px;
				border: 1px solid @popupCancelBorderColor;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: @popupCancelTextColor;
				margin-right: 44px;
				background: @popupCancelBgColor;
				cursor: pointer;
			}

			.confirm {
				width: 102px;
				height: 36px;
				background: #3278FF;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: #FFFFFF;
				cursor: pointer;
			}
		}

		// 提交成功弹窗
		.pop-content-submit {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 24px;
			border-bottom: 1px solid @demoitemtabsBGColor;

			.title {
				padding-top: 24px;
				font-size: 20px;
				font-weight: 500;
				// color: #FFFFFF;
				color: @recommendMusicianListTextColor;
				margin-bottom: 12px;
			}

			.desc {
				font-size: 14px;
				font-weight: 500;
				color: rgba(255, 255, 255, 0.6);
			}
		}

		.pop-footer-submit {
			display: flex;
			align-items: center;
			height: 68px;
			justify-content: center;

			.cancel {
				width: 102px;
				height: 36px;
				border-radius: 4px;
				border: 1px solid @popupCancelBorderColor;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				color: @popupCancelTextColor;
				// margin-right: 44px;
				background: @popupCancelBgColor;
			}
		}

		// 充值明细弹窗
		.pop-content-submit1 {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 24px;
			border-bottom: 1px solid @demoitemtabsBGColor;
			max-height: 40vh;

			::v-deep .el-table {
				background: @popupBackgroundColor;
				border-color: @popupCommonBorderColor;
				// border: 1px solid @popupBackgroundColor;
			}

			::v-deep .el-table__row {
				background: @popupBackgroundColor;
			}

			::v-deep .el-table th.el-table__cell {
				background: @popupBackgroundColor;

			}

			::v-deep .el-table__header-wrapper {
				// border-color: @popupCommonBorderColor;
				// display: flex;
				// justify-content: center;
				// background-color: red;
			}

			// ::v-deep has-gutter{

			// }


			::v-deep .el-table--border .el-table__cell {
				// border-right: none;
				// 	&:hover{
				// 		background-color: red;
				// 	}
			}

			::v-deep .el-table td.el-table__cell {
				color: @recommendMusicianListTextColor;
				border-color: @popupCommonBorderColor;
			}

			::v-deep .el-table__body-wrapper {
				// display: flex;
				// justify-content: center;
				border-color: @popupCommonBorderColor;
			}

			::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {

				background-color: @tableHoverBgColor  !important;

			}

			// el-table--enable-row-transition
			// ::v-deep tr:hover{
			// 	background-color: red;
			// }
			// ::v-deep .el-table__body:hover{
			// 	background-color: red;
			// }
		}

	}
</style>
